import React, { useEffect, useState } from 'react';

function Footer() {
    const [consentGiven, setConsentGiven] = useState(false);

    useEffect(() => {
        // Function to check consent status
        const checkConsentStatus = () => {
            if (window.one_trust_ready) {
                const domainData = window.OneTrust.GetDomainData();
                const consentedGroups = domainData.Groups.filter(group => 
                    group.HasConsentOptOut && group.GroupName !== 'Strictly Necessary Cookies'
                );
                const hasConsented = consentedGroups.some(group => 
                    group.HasConsentOptOut && group.IsConsented
                );
                setConsentGiven(hasConsented);
                return true;
            }
            return false;
        };

        // Only check consent status, don't initialize anything else
        if (window.one_trust_ready) {
            checkConsentStatus();
        }

        // Cleanup
        return () => {
            // Any cleanup needed
        };
    }, []);

    return (
        <footer className="w-full mt-4 text-center text-[#0a0a0a]">
            <img src="./Assets/Images/moons.png" alt="Moon Phases" className="pt-4 w-[200px] mx-auto" />
            <div className="text-xs my-4">
                © 2025 WARNER MUSIC PHILIPPINES
                <span className="mx-2">|</span>
                <a href="https://privacy.wmg.com/wmi/privacy" className="underline hover:text-gray-600">Privacy Policy</a>
                <span className="mx-2">|</span>
                <a href="https://privacy.wmg.com/wmi/terms-of-use" className="underline hover:text-gray-600">Terms of Use</a>
                <span className="mx-2">|</span>
                <a href="https://www.wminewmedia.com/cookies-policy/" target="_blank" className="underline hover:text-gray-600">Cookies Policy</a>
                <span className="mx-2">|</span>
                <a className="ot-sdk-show-settings underline hover:text-gray-600">Do Not Sell or Share My Personal Information</a>
            </div>
            <div id="onetrust-consent-sdk"></div>
        </footer>
    );
}

export default Footer;
